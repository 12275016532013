<template>
  <v-card flat class="px-2">
    <v-row>
      <v-col cols="12">
        <v-card-text>
          <v-row>
            <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12">
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Status Pegawai :</td>
                      <td>
                        <v-select
                          v-if="isUpdate"
                          :items="statusPegawaiList"
                          outlined
                          dense
                          hide-details
                          :disabled="!$_.isEmpty(employee.status_peg)"
                          v-model="employee.status_peg"
                        ></v-select>
                        <span v-else>{{ employee.status_peg }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12">
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Jenis Pegawai :</td>
                      <td>
                        <v-select
                          v-if="isUpdate"
                          :items="jenisPegawaiList"
                          outlined
                          dense
                          hide-details
                          :disabled="!$_.isEmpty(employee.jenis_peg)"
                          v-model="employee.jenis_peg"
                        ></v-select>
                        <span v-else>{{ employee.jenis_peg }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12">
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">MKS :</td>
                      <td>
                        <span>{{ employee.mks }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12">
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">MKG :</td>
                      <td>
                        <span>{{ employee.mkg }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              :xl="employee.status_peg == 'PNS' ? 4 : 6"
              :lg="employee.status_peg == 'PNS' ? 4 : 6"
              :md="employee.status_peg == 'PNS' ? 4 : 6"
              sm="12"
              xs="12"
              class="px-0"
            >
              <p class="ml-3 subtitle-1 font-weight-bold headline-color">
                CPNS
              </p>
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">TMT :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          type="date"
                          outlined
                          dense
                          hide-details
                          :disabled="!$_.isEmpty(employee.cpns.tgl_mulai_CPNS)"
                          v-model="employee.cpns.tgl_mulai_CPNS"
                        ></v-text-field>
                        <span v-else>{{
                          employee.cpns.tgl_mulai_CPNS | date
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">No SK CPNS :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          outlined
                          dense
                          hide-details
                          v-model="employee.cpns.no_sk_CPNS"
                        ></v-text-field>
                        <span v-else>{{ employee.cpns.no_sk_CPNS }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Tanggal SK :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          type="date"
                          outlined
                          dense
                          hide-details
                          v-model="employee.cpns.tgl_sk_CPNS"
                        ></v-text-field>
                        <span v-else>{{
                          employee.cpns.tgl_sk_CPNS | date
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col
              cols="12"
              xl="4"
              lg="4"
              md="4"
              sm="12"
              xs="12"
              class="px-0"
              v-show="employee.status_peg == 'PNS'"
            >
              <p class="ml-3 subtitle-1 font-weight-bold headline-color">
                PNS
              </p>
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">TMT :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          type="date"
                          outlined
                          dense
                          hide-details
                          :disabled="!$_.isEmpty(employee.pns.tgl_mulai_PNS)"
                          v-model="employee.pns.tgl_mulai_PNS"
                        ></v-text-field>
                        <span v-else>{{
                          employee.pns.tgl_mulai_PNS | date
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">No SK CPNS :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          outlined
                          dense
                          hide-details
                          v-model="employee.pns.no_sk_PNS"
                        ></v-text-field>
                        <span v-else>{{ employee.pns.no_sk_PNS }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Tanggal SK :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          type="date"
                          outlined
                          dense
                          hide-details
                          v-model="employee.pns.tgl_sk_PNS"
                        ></v-text-field>
                        <span v-else>{{ employee.pns.tgl_sk_PNS | date }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col
              cols="12"
              :xl="employee.status_peg == 'PNS' ? 4 : 6"
              :lg="employee.status_peg == 'PNS' ? 4 : 6"
              :md="employee.status_peg == 'PNS' ? 4 : 6"
              sm="12"
              xs="12"
              class="px-0 pt-14"
            >
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">PMK :</td>
                      <td>
                        <v-card-actions class="pa-0" v-if="isUpdate">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="employee.PMK"
                          ></v-text-field>
                          <span class="px-5">Tahun</span>
                          <v-text-field
                            class="ml-1"
                            outlined
                            dense
                            hide-details
                            v-model="employee.PMK_bulan"
                          ></v-text-field>
                          <span class="px-5">Bulan</span>
                        </v-card-actions>
                        <span v-else
                          >{{ employee.PMK ? `${employee.PMK} Tahun` : "" }}
                          {{
                            employee.PMK_bulan
                              ? `${employee.PMK_bulan} Bulan`
                              : ""
                          }}</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">No Karpeg :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          outlined
                          dense
                          hide-details
                          v-model="employee.no_karpeg"
                        ></v-text-field>
                        <span v-else>{{ employee.no_karpeg }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">No Karis/SU :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          outlined
                          dense
                          hide-details
                          v-model="employee.no_karis_su"
                        ></v-text-field>
                        <span v-else>{{ employee.no_karis_su }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
              class="px-0"
            >
              <p class="ml-3 subtitle-1 font-weight-bold headline-color">
                Unit Kerja
              </p>
              <v-simple-table id="table-custom">
                <tbody>
                  <tr>
                    <td class="font-weight-bold">Unit Utama :</td>
                    <td>
                      <v-autocomplete
                        v-if="isUpdate"
                        :items="unit_utama"
                        :loading="loadingUnitUtama"
                        outlined
                        dense
                        hide-details
                        :disabled="!$_.isEmpty(unit_kerja.unit_utama)"
                        v-model="unit_kerja.unit_utama"
                        item-text="singkatan"
                        item-value="unit_utama_id"
                        @change="changeUnit(1)"
                        @click:clear="changeUnit(1)"
                        return-object
                      ></v-autocomplete>
                      <span v-else>{{
                        !$_.isEmpty(unit_kerja.unit_utama)
                          ? unit_kerja.unit_utama.singkatan
                          : "-"
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Unit Kerja 2 :</td>
                    <td>
                      <v-autocomplete
                        v-if="isUpdate"
                        :items="unit2List"
                        :loading="loadingUnit"
                        outlined
                        dense
                        hide-details
                        :disabled="!$_.isEmpty(unit_kerja.unit_kerja_2)"
                        v-model="unit_kerja.unit_kerja_2"
                        item-text="singkatan"
                        item-value="unit_kerja_2_id"
                        @change="changeUnit(2)"
                        @click:clear="changeUnit(2)"
                        return-object
                      ></v-autocomplete>
                      <span v-else>{{
                        !$_.isEmpty(unit_kerja.unit_kerja_2)
                          ? unit_kerja.unit_kerja_2.singkatan
                          : "-"
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Unit Kerja 3 :</td>
                    <td>
                      <v-autocomplete
                        v-if="isUpdate"
                        :items="unit3List"
                        :loading="loadingUnit"
                        outlined
                        dense
                        hide-details
                        :disabled="!$_.isEmpty(unit_kerja.unit_kerja_3)"
                        v-model="unit_kerja.unit_kerja_3"
                        item-text="singkatan"
                        item-value="unit_kerja_3_id"
                        @change="changeUnit(3)"
                        @click:clear="changeUnit(3)"
                        return-object
                      ></v-autocomplete>
                      <span v-else>{{
                        !$_.isEmpty(unit_kerja.unit_kerja_3)
                          ? unit_kerja.unit_kerja_3.singkatan
                          : "-"
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Unit Kerja 4 :</td>
                    <td>
                      <v-autocomplete
                        v-if="isUpdate"
                        :items="unit4List"
                        :loading="loadingUnit"
                        outlined
                        dense
                        hide-details
                        :disabled="!$_.isEmpty(unit_kerja.unit_kerja_4)"
                        v-model="unit_kerja.unit_kerja_4"
                        item-text="singkatan"
                        item-value="unit_kerja_4_id"
                        @change="changeUnit(4)"
                        @click:clear="changeUnit(4)"
                        return-object
                      ></v-autocomplete>
                      <span v-else>{{
                        !$_.isEmpty(unit_kerja.unit_kerja_4)
                          ? unit_kerja.unit_kerja_4.singkatan
                          : "-"
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="px-0">
              <p class="ml-3 subtitle-1 font-weight-bold headline-color">
                Jabatan dan Eselon
              </p>
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Jabatan :</td>
                      <td>
                        <v-autocomplete
                          v-if="isUpdate"
                          :items="jabatanList"
                          :loading="loading_jabatan"
                          outlined
                          dense
                          hide-details
                          :disabled="
                            !$_.isEmpty(employee.jabatan_eselon.jabatan)
                          "
                          v-model="employee.jabatan_eselon.jabatan"
                          item-text="nama_jabatan"
                          item-value="jabatan_id"
                          return-object
                        ></v-autocomplete>
                        <span v-else>{{
                          employee.jabatan_eselon.jabatan &&
                            employee.jabatan_eselon.jabatan.nama_jabatan
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Eselon :</td>
                      <td>
                        <v-autocomplete
                          v-if="isUpdate"
                          :items="eselonList"
                          :loading="loading_eselon"
                          outlined
                          dense
                          hide-details
                          :disabled="
                            !$_.isEmpty(employee.jabatan_eselon.eselon)
                          "
                          v-model="employee.jabatan_eselon.eselon"
                          item-text="keterangan"
                          item-value="eselon_id"
                          return-object
                        ></v-autocomplete>
                        <span v-else>{{
                          employee.jabatan_eselon.eselon &&
                            employee.jabatan_eselon.eselon.keterangan
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">TMT :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          type="date"
                          outlined
                          dense
                          hide-details
                          v-model="employee.jabatan_eselon.tmt_jabatan"
                        ></v-text-field>
                        <span v-else>{{
                          employee.jabatan_eselon.tmt_jabatan | date
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Nomor SK :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          outlined
                          dense
                          hide-details
                          v-model="employee.jabatan_eselon.no_sk_jabatan"
                        ></v-text-field>
                        <span v-else>{{
                          employee.jabatan_eselon.no_sk_jabatan
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Tanggal SK :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          type="date"
                          outlined
                          dense
                          hide-details
                          v-model="employee.jabatan_eselon.tgl_sk_jabatan"
                        ></v-text-field>
                        <span v-else>{{
                          employee.jabatan_eselon.tgl_sk_jabatan | date
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="px-0">
              <p class="ml-3 subtitle-1 font-weight-bold headline-color">
                Golongan dan Kepangkatan
              </p>
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Golongan :</td>
                      <td>
                        <v-autocomplete
                          v-if="isUpdate"
                          :items="golonganList"
                          :loading="loading_golongan"
                          outlined
                          dense
                          hide-details
                          :disabled="!$_.isEmpty(employee.golongan_pangkat.gol)"
                          v-model="employee.golongan_pangkat.gol"
                          item-text="gol"
                          item-value="gol"
                          return-object
                        ></v-autocomplete>
                        <span v-else>{{
                          employee.golongan_pangkat.gol &&
                            employee.golongan_pangkat.gol.gol
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Pangkat :</td>
                      <td>
                        <v-autocomplete
                          v-if="isUpdate"
                          :items="pangkatList"
                          :loading="loading_pangkat"
                          outlined
                          dense
                          hide-details
                          :disabled="
                            !$_.isEmpty(employee.golongan_pangkat.pangkat)
                          "
                          v-model="employee.golongan_pangkat.pangkat"
                          item-text="pangkat"
                          item-value="pangkat_id"
                          return-object
                        ></v-autocomplete>
                        <span v-else>{{
                          employee.golongan_pangkat.pangkat &&
                            employee.golongan_pangkat.pangkat.pangkat
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">TMT :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          type="date"
                          outlined
                          dense
                          hide-details
                          :disabled="
                            !$_.isEmpty(employee.golongan_pangkat.tmt_gol)
                          "
                          v-model="employee.golongan_pangkat.tmt_gol"
                        ></v-text-field>
                        <span v-else>{{
                          employee.golongan_pangkat.tmt_gol | date
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Nomor SK :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          outlined
                          dense
                          hide-details
                          v-model="employee.golongan_pangkat.no_sk_gol"
                        ></v-text-field>
                        <span v-else>{{
                          employee.golongan_pangkat.no_sk_gol
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Tanggal SK :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          type="date"
                          outlined
                          dense
                          hide-details
                          v-model="employee.golongan_pangkat.tgl_sk_gol"
                        ></v-text-field>
                        <span v-else>{{
                          employee.golongan_pangkat.tgl_sk_gol | date
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col
              cols="12"
              xl="4"
              lg="4"
              md="4"
              sm="12"
              xs="12"
              class="px-0 pt-14"
            >
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Status Keaktifan :</td>
                      <td>
                        <v-select
                          v-if="isUpdate"
                          :items="statusAktifList"
                          outlined
                          dense
                          hide-details
                          v-model="employee.status_aktif"
                        ></v-select>
                        <span v-else>{{ employee.status_aktif }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        Perkiraan Tanggal Pensiun :
                      </td>
                      <td>
                        <span>{{ employee.perkiraan_pensiun }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
    <v-card-actions v-if="!isApprove">
      <v-btn
        small
        outlined
        color="#717171"
        class="px-5 text-capitalize"
        @click="visible = true"
        >Mutasi Jabatan / Unit Kerja</v-btn
      >
      <v-btn
        small
        outlined
        color="#717171"
        class="px-5 text-capitalize"
        @click="handlePendidikanDialog"
        >Mutasi Pendidikan</v-btn
      >
      <v-btn
        small
        outlined
        color="#717171"
        class="px-5 text-capitalize"
        @click="handleGolonganDialog"
        >Mutasi Golongan</v-btn
      >
    </v-card-actions>
    <v-dialog v-model="visible" persistent max-width="400">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 pt-0 font-weight-bold">
          Pilih Mutasi Jabatan / Unit Kerja
        </v-card-text>
        <v-divider class="mb-5" />
        <v-btn
          block
          color="#297BBF"
          class="caption text-capitalize"
          dark
          @click="handleJabatanDialog"
        >
          Mutasi Jabatan dan Unit Kerja Pegawai
        </v-btn>
        <v-btn
          block
          color="#297BBF"
          class="mt-2 caption text-capitalize"
          dark
          @click="handleJabatanExchangeDialog"
        >
          Mutasi Jabatan dan Unit Kerja Dua Pegawai
        </v-btn>
        <v-btn
          block
          color="#297BBF"
          class="mt-2 caption text-capitalize"
          dark
          @click="handleSKDialog"
        >
          Perubahan SK dan TMT Jabatan
        </v-btn>
      </v-card>
    </v-dialog>
    <MutasiGolonganDialog ref="mutasiGolongan" />
    <MutasiPendidikanDialog ref="mutasiPendidikan" />
    <MutasiJabatanUnitKerjaDialog ref="mutasiJabatanUnitKerja" />
    <MutasiJabatanUnitKerjaDialog :unitDisabled="true" ref="mutasiJabatanSK" />
    <MutasiJabatanUnitKerjaExchangeDialog
      ref="mutasiJabatanUnitKerjaExchange"
    />
  </v-card>
</template>
<script>
import { mapEmployeeRootField } from "@/store/helpers";
import PublicService from "@/services/resources/public.service";
const MutasiGolonganDialog = () => import("@/components/Dialog/MutasiGolongan");
const MutasiPendidikanDialog = () =>
  import("@/components/Dialog/MutasiPendidikan");
const MutasiJabatanUnitKerjaDialog = () =>
  import("@/components/Dialog/MutasiJabatanUnitKerja");
const MutasiJabatanUnitKerjaExchangeDialog = () =>
  import("@/components/Dialog/MutasiJabatanUnitKerjaExchange");

export default {
  components: {
    MutasiGolonganDialog,
    MutasiPendidikanDialog,
    MutasiJabatanUnitKerjaDialog,
    MutasiJabatanUnitKerjaExchangeDialog
  },
  props: {
    isUpdate: {
      type: Boolean,
      required: false,
      default: false
    },
    isApprove: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      loading_golongan: false,
      loading_eselon: false,
      loading_jabatan: false,
      loading_pangkat: false,
      loadingUnitUtama: false,
      loadingUnit: false,
      statusPegawaiList: ["CPNS", "PNS", "NON-ASN"],
      statusAktifList: ["Aktif", "Berhenti", "Meninggal", "Pensiun", "Pindah"],
      jenisPegawaiList: ["PNS Dipekerjakan", "PNS Diperbantukan", "PNS Pusat"],
      golonganList: [],
      eselonList: [],
      jabatanList: [],
      pangkatList: [],
      unit_utama: [],
      unitList: [],
      unit2List: [],
      unit3List: [],
      unit4List: []
    };
  },
  computed: {
    ...mapEmployeeRootField({
      personal: "personal",
      employee: "employee",
      unit_kerja: "unit_kerja"
    })
  },
  methods: {
    handleToggle() {
      this.$emit("toggle");
    },
    handlePendidikanDialog() {
      this.$refs.mutasiPendidikan.populate({
        NIP: this.personal.NIP,
        nama: this.personal.nama,
        jenjang: this.personal.pendidikan.jenjang,
        bidang_studi: this.personal.pendidikan.bidang_studi,
        nama_sekolah: this.personal.pendidikan.nama_sekolah,
        thn_lulus: this.personal.pendidikan.thn_lulus
      });
    },
    handleGolonganDialog() {
      this.$refs.mutasiGolongan.populate({
        NIP: this.personal.NIP,
        nama: this.personal.nama,
        gol: this.employee.golongan_pangkat.gol,
        pangkat: this.employee.golongan_pangkat.pangkat,
        tgl_mulai_PNS: this.employee.pns.tgl_mulai_PNS,
        no_sk_PNS: this.employee.pns.no_sk_PNS,
        tgl_sk_PNS: this.employee.pns.tgl_sk_PNS
      });
    },
    handleSKDialog() {
      this.visible = false;
      this.$refs.mutasiJabatanSK.populate({
        NIP: this.personal.NIP,
        nama: this.personal.nama,
        unit_utama: this.unit_kerja.unit_utama?.unit_utama_id,
        unit_utama_nama: this.unit_kerja.unit_utama?.singkatan,
        unit_kerja_2: this.unit_kerja.unit_kerja_2?.unit_kerja_2_id,
        unit_kerja_2_nama: this.unit_kerja.unit_kerja_2?.singkatan,
        unit_kerja_3: this.unit_kerja.unit_kerja_3?.unit_kerja_3_id,
        unit_kerja_3_nama: this.unit_kerja.unit_kerja_3?.singkatan,
        unit_kerja_4: this.unit_kerja.unit_kerja_4?.unit_kerja_4_id,
        unit_kerja_4_nama: this.unit_kerja.unit_kerja_4?.singkatan,
        jabatan: this.employee.jabatan_eselon.jabatan?.jabatan_id,
        jabatan_nama: this.employee.jabatan_eselon.jabatan?.nama_jabatan,
        eselon: this.employee.jabatan_eselon.eselon?.eselon_id,
        eselon_nama: this.employee.jabatan_eselon.eselon?.keterangan,
        kelas: this.employee.jabatan_eselon.jabatan?.kelas_jabatan,
        tgl_mulai_PNS: this.employee.pns.tgl_mulai_PNS,
        no_sk_PNS: this.employee.pns.no_sk_PNS,
        tgl_sk_PNS: this.employee.pns.tgl_sk_PNS
      });
    },
    handleJabatanExchangeDialog() {
      this.visible = false;
      this.$refs.mutasiJabatanUnitKerjaExchange.populate({
        NIP: this.personal.NIP,
        nama: this.personal.nama,
        unit_utama: this.unit_kerja.unit_utama,
        unit_kerja_2: this.unit_kerja.unit_kerja_2,
        unit_kerja_3: this.unit_kerja.unit_kerja_3,
        unit_kerja_4: this.unit_kerja.unit_kerja_4,
        jabatan: this.employee.jabatan_eselon.jabatan,
        eselon: this.employee.jabatan_eselon.eselon,
        kelas: this.employee.jabatan_eselon.eselon,
        tgl_mulai_PNS: this.employee.pns.tgl_mulai_PNS,
        no_sk_PNS: this.employee.pns.no_sk_PNS,
        tgl_sk_PNS: this.employee.pns.tgl_sk_PNS
      });
    },
    handleJabatanDialog() {
      this.visible = false;
      this.$refs.mutasiJabatanUnitKerja.populate({
        NIP: this.personal.NIP,
        nama: this.personal.nama,
        unit_utama: this.unit_kerja.unit_utama?.unit_utama_id,
        unit_utama_nama: this.unit_kerja.unit_utama?.singkatan,
        unit_kerja_2: this.unit_kerja.unit_kerja_2?.unit_kerja_2_id,
        unit_kerja_2_nama: this.unit_kerja.unit_kerja_2?.singkatan,
        unit_kerja_3: this.unit_kerja.unit_kerja_3?.unit_kerja_3_id,
        unit_kerja_3_nama: this.unit_kerja.unit_kerja_3?.singkatan,
        unit_kerja_4: this.unit_kerja.unit_kerja_4?.unit_kerja_4_id,
        unit_kerja_4_nama: this.unit_kerja.unit_kerja_4?.singkatan,
        jabatan: this.employee.jabatan_eselon.jabatan?.jabatan_id,
        jabatan_nama: this.employee.jabatan_eselon.jabatan?.nama_jabatan,
        eselon: this.employee.jabatan_eselon.eselon?.eselon_id,
        eselon_nama: this.employee.jabatan_eselon.eselon?.keterangan,
        kelas: this.employee.jabatan_eselon.jabatan?.kelas_jabatan,
        tgl_mulai_PNS: this.employee.pns.tgl_mulai_PNS,
        no_sk_PNS: this.employee.pns.no_sk_PNS,
        tgl_sk_PNS: this.employee.pns.tgl_sk_PNS
      });
    },
    changeUnit(type = 2) {
      if (type == 1) {
        this.unit2List =
          this.unitList &&
          this.unitList.filter(d =>
            d.unit_kerja_2_id.includes(
              this.unit_kerja.unit_utama?.unit_utama_id
            )
          );
        this.unit_kerja.unit_kerja_2 = null;
        this.unit_kerja.unit_kerja_3 = null;
        this.unit_kerja.unit_kerja_4 = null;
      } else if (type == 2) {
        this.unit3List = this.unit2List.find(
          d =>
            d.unit_kerja_2_id == this.unit_kerja.unit_kerja_2?.unit_kerja_2_id
        )?.unit_kerja3;
        this.unit_kerja.unit_kerja_3 = null;
        this.unit_kerja.unit_kerja_4 = null;
      } else if (type == 3) {
        this.unit4List = this.unit3List.find(
          d =>
            d.unit_kerja_3_id == this.unit_kerja.unit_kerja_3?.unit_kerja_3_id
        )?.unit_kerja4;
        this.unit_kerja.unit_kerja_4 = null;
      }
    },
    populateUnit() {
      this.unit2List = this.unitList.filter(d =>
        d.unit_kerja_2_id.includes(this.unit_kerja.unit_utama?.unit_utama_id)
      );
      this.unit3List = this.unit2List.find(
        d => d.unit_kerja_2_id == this.unit_kerja.unit_kerja_2?.unit_kerja_2_id
      )?.unit_kerja3;
      this.unit4List = this.unit3List.find(
        d => d.unit_kerja_3_id == this.unit_kerja.unit_kerja_3?.unit_kerja_3_id
      )?.unit_kerja4;
    },
    // Service
    async getListUnitUtama() {
      try {
        this.loadingUnitUtama = true;
        await PublicService.getUnitUtamaList()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.unit_utama = data;
              this.getListUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnitUtama = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await PublicService.getUnitKerjaList()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.unitList = data;
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getLov(type) {
      try {
        this[`loading_${type}`] = true;
        await PublicService[`${type}GetList`]({
          search: ""
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this[`${type}List`] = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this[`loading_${type}`] = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getLov("golongan");
    this.getLov("eselon");
    this.getLov("jabatan");
    this.getLov("pangkat");
    this.getListUnitUtama();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.675rem;
  }
}
#table-custom .v-input {
  font-size: 0.675rem;
}
</style>
